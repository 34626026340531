<template>
  <div class="">
    <center-title title="可申请对账订单" :bottom="15"></center-title>
    <el-form ref="form" :inline="true" label-width="0px">
      <el-form-item>
        <el-button type="primary" class="whtie submit" @click="apply"
          >申请</el-button
        >
      </el-form-item>
    </el-form>
    <el-table :data="orderLists" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column align="center" prop="order_sn" label="订单编号">
      </el-table-column>
      <el-table-column align="center" prop="goods_price" label="订单金额">
        <template slot-scope="scope">
          <make-priceformat
            :price="scope.row.goods_price"
            :subscriptSize="14"
            :firstSize="14"
            :secondSize="14"
            color="#333333"
          ></make-priceformat>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="order_amount" label="实际支付金额">
        <template slot-scope="scope">
          <make-priceformat
            :price="scope.row.order_amount"
            :subscriptSize="14"
            :firstSize="14"
            :secondSize="14"
            color="#333333"
          ></make-priceformat>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="confirm_take_time"
        label="订单完成时间"
      >
      </el-table-column>
    </el-table>
    <div class="row-center pagination mt30">
      <make-pagination
        @resetList="resetList"
        :orderObject="orderObject"
      ></make-pagination>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import MakePagination from "@/components/MakePagination";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
    "make-pagination": MakePagination,
  },
  data() {
    return {
      orderLists: [],
      orderObject: {},
      page_no: 1,
      page_size: 10,
      multipleSelection: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    getNoSettlementOrderLists() {
      this.$api
        .getNoSettlementOrderListsApi({
          page_no: this.page_no,
          page_size: this.page_size,
        })
        .then((res) => {
          this.orderLists = res.data.lists;
          this.orderObject = res.data;
        });
    },
    resetList(e) {
      this.page_no = e;
      this.getNoSettlementOrderLists();
    },
    handleSelectionChange(val) {
      this.multipleSelection = [];
      for (let i = 0; i < val.length; i++) {
        this.multipleSelection.push(val[i].id);
      }
    },
    apply() {
      this.$api
        .addApplySettlementApi({
          applySettlementOrderLists: this.multipleSelection,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
              onClose: () => {
                this.$router.go(-1);
              },
            });
          }
        });
    },
  },
  created() {
    this.getNoSettlementOrderLists();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
